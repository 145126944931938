*,
*:before,
*:after {
    box-sizing: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root main {
    flex-grow: 1;
}
